
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import FileUpload, { VueUploadItem } from 'vue-upload-component';
import formatDate from 'xe-utils/toDateString'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    FileUpload
  },
  setup () {
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]

    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;

    const checkPermissions = (key: string) => {
      // console.log(user?.Permissions)
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("DepartmentCreate");
    const canUpdate = checkPermissions("DepartmentUpdate");
    const canDelete = checkPermissions("DepartmentDelete");

    const gridOptions: GridOptions = {
      stripe: false,
      title: '程序書管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      multiselect: false,
      printConfig: { sheetName: '程序書管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '程序書管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        {
          field: 'CreatedTime',
          title: '建立時間',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
          formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : ''
        },
        {
          field: 'Name',
          title: '名稱',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
        },        
      ],
      promises: {
        query: model ? (params) => {
          params.condition!.and("Type", Operator.Equal, 1);
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          return model.dispatch('files/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('files/query') : undefined,
        //save: model ? (params) => model.dispatch('files/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true },
      // treeConfig: { children: 'Children' }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'Name',
          title: '名稱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Number',
          title: '編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入編號', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Email',
          title: '服務信箱',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入服務信箱', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ContactPerson',
          title: '聯絡人',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '聯絡人', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ContactPhone',
          title: '聯絡電話',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入聯絡電話', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'SupervisorId',
          title: '負責人',
          span: 12,
          slots: { default: "column-supervisorId" }
        },
      ],
      rules: {
        Name: [{ type: 'string', required: true }],
        Email: [{ required: true, message: "Email格式錯誤", pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }],
        ContactPhone: [
          {
            type: "string",
            pattern: new RegExp(
              "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
            ),
          },
        ],
      }
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Person.Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Email",
          title: "Email",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: (value: any) => model!.dispatch("employee/find", value), // eslint-disable-line
        query: (params: any) => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    const initData = (row: any, callback: any) => {
      callback();
    }

    const fileGrid = ref<any>()
    const fileModal = reactive({
      visible: false,
      selectedRow: {} as any,
      uploadOptions: {
        promises: {
          async query() {
            var condition = new Condition("ProductId", Operator.Equal, fileModal.selectedRow.Id.toString());
            const sortings = [{ column: "CreatedTime", order: 0 }];
            return await model?.dispatch('files/query', {
              condition: condition,
              sortings: sortings,
            });
          },
          async insert(formData: FormData) {
            return await model?.dispatch('files/insert', { formData, mode: 'other', entityId: '' + fileModal.selectedRow.Id, fileType: 1 });
          },
          async delete(id: number) {
            await model?.dispatch('files/delete', id);
            await grid.value.refresh();
          }
        }
      },
      show(row: any) {
        fileModal.selectedRow = row;
        if (fileGrid.value) fileGrid.value.refresh();
      }
    })

    const uploadFiles = ref<VueUploadItem[]>([]);
    const validExt = ['.gif', '.jpg', '.jpeg', '.png', '.webp', '.zip', '.rar', '.doc', '.docx', '.ppt', '.pptx', '.xlsx', '.xls', '.csv', '.txt', '.pdf'];
    const upload = () => {
      if (uploadFiles.value.length === 0) return;
      const formData = new FormData();
      for (const file of uploadFiles.value) {
        if (!file.size || !file.name || !file.file) return;
        if (file.size >= (25 * 1024 * 1024)) {
            CloudFun.send('error', { subject: '附件上傳失敗！', content: '檔案大小不得超過25M' })
            uploadFiles.value = [];
            return;
        }

        const ext = '.' + file.name.split('.')?.[1];
        if (validExt && validExt.indexOf(ext) === -1) {
          uploadFiles.value = [];
          CloudFun.send('error', { subject: '附件上傳失敗！', content: '不支援此檔案類型' })
          return;
        }
        formData.append("files", file.file);
      }

      fileModal?.uploadOptions?.promises?.insert(formData).then(async() => {
        CloudFun.send('info', { subject: '執行成功', content: '上傳完成' })
        //await fileModal?.uploadOptions?.promises?.query()
        await grid.value.refresh();
      },
        failure => CloudFun.send('error', { subject: '操作失敗！', content: failure })
      ).finally(() => {
        uploadFiles.value = [];
      });
    }

    const s2ab = (s: any) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    const downloadFile = async(id: number, name: string) => {
      if(id && name) {
        try {
          var data = await model!.dispatch('files/download', { key: id });
          const file = new Blob([s2ab(atob(data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = name;
          link.click();
        } catch (e: any) {
          CloudFun.send("error", {
          subject: "執行失敗",
                  content: e,
          });
        }
      }
    }

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      selectorOptions,
      initData,
      canCreate,
      fileGrid,
      fileModal,
      uploadFiles,
      upload,
      downloadFile,
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
